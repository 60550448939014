import React, { useState } from 'react'
import Background from './Background'
import Header from './Header'

const LandingPage = () => {
  const [showMoreCOO, setShowMoreCOO] = useState(false)
  const [showMoreOpposition, setShowMoreOpposition] = useState(false)

  return (
    <div className="relative flex flex-col h-screen">
      <Header />
      <section className="relative flex flex-col items-center py-12 bg-white z-10">
        <h2 className="text-3xl font-bold mb-8">Products</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 w-full max-w-6xl px-4">
          <div className="cursor-pointer bg-gray-100 p-6 rounded-lg shadow-md">
            <a href="https://patents.chat/" target="_blank">
              <h3 className="text-xl font-semibold mb-2">Patents.chat</h3>
              <p>ChatGPT-like platform for natural language querying and generation for patent and technical documents.</p>
            </a>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <a href="https://www.scenarionaut.com/" target="_blank">
              <h3 className="text-xl font-semibold mb-2">Scenarionaut.com</h3>
              <p>Automated, customizable training and evaluation platform for educators and trainers, with a current prototype geared towards high-school biology curriculum.</p>
            </a>
          </div>
          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Trademarks.chat</h3>
            <p>ChatGPT-like platform for natural language querying and generation for preparing and analyzing US trademark applications and registrations.</p>
            <div className="pt-2 italic">Coming soon</div>
          </div>
        </div>
      </section>

      {/* News Section
      <section className="relative py-12 bg-gray-100 z-10" id="news">
        <h2 className="text-3xl font-bold text-center mb-4">ForGen AI in the News</h2>
        <div className="max-w-2xl mx-auto px-4 space-y-8">

          {/* News Article 2
          <article className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">Opposition Filed by Geotechnical</h3>
            <p>
              Recently, a frivolous opposition was filed against ForGen AI by <a href="https://forgen.com/" target="_blank">Forgen</a>, citing previous registrations directed to environmental construction and disaster response solutions. The opposition references marks already reviewed and approved by the examining attorney, who found no likelihood of confusion.
            </p>
            {showMoreOpposition && (
              <div className="mt-2">
                <p>
                  The opposition is filed by Forgen, LLC, a U.S. company specializing in environmental remediation, geotechnical construction, flood control, ecosystem restoration, and disaster mitigation and recovery. The opposition asserts a likelihood of confusion between the marks, with the only substantive commentary stating, the services are related because, "[f]or example, 'technology consultation in the field of artificial intelligence' can be used for purposes of improving the constructing services and environmental remediation services Forgen renders."
                  ForGen AI's in-house, trademark analytical platform states, "Forgen admits itself it does not provide any such related services, but rather is a customer of those services. By Forgen's 'logic', it could assert the same against any providers of goods or services it may use to perform its <b>entirely different</b> services."
                </p>
                <div className="mt-4">
                  <h4 className="font-semibold text-lg">Registrations Involved</h4>
                  <table className="table-auto w-full mt-2 border-collapse border border-gray-200">
                    <thead>
                      <tr>
                        <th className="border px-4 py-2">Registration Number</th>
                        <th className="border px-4 py-2">Applicant</th>
                        <th className="border px-4 py-2">Goods/Services Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td className="border px-4 py-2">
                          <a href="https://tsdr.uspto.gov/#caseNumber=98251656&caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch" target="_blank">98251656</a>
                        </td>
                        <td className="border px-4 py-2">ForGen AI, LLC (Opposed)</td>
                        <td className="border px-4 py-2">
                          Technology consultation in artificial intelligence; AI software customization; consulting for online non-downloadable software; generative AI software consulting; AI research; non-downloadable chatbot software for simulating conversations; generative AI platforms for text generation, messaging, word processing; SAAS using AI for text generation, understanding text input, and conversation simulation.
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-4 py-2">
                          <a href="https://tsdr.uspto.gov/#caseNumber=98121907&caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch" target="_blank">98121907</a>
                        </td>
                        <td className="border px-4 py-2">Forgen, LLC</td>
                        <td className="border px-4 py-2">
                          Geotechnical construction services, including deep foundations, slurry walls, soil stabilization, and soil improvement; civil construction services, including grading, dam and levee construction, wetlands, stream and channel restoration; excavation services; project management; wildfire clean-up; disaster clean-up, environmental remediation, soil stabilization, soil containment, localization and consolidation of soil, site assessments.
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-4 py-2">
                          <a href="https://tsdr.uspto.gov/#caseNumber=98121918&caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch" target="_blank">98121918</a>
                        </td>
                        <td className="border px-4 py-2">Forgen, LLC</td>
                        <td className="border px-4 py-2">
                          Disaster response services, including removal of debris, soil, and vegetation; environmental remediation services, including soil treatment.
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-4 py-2">
                          <a href="https://tsdr.uspto.gov/#caseNumber=98121911&caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch" target="_blank">98121911</a>
                        </td>
                        <td className="border px-4 py-2">Forgen, LLC</td>
                        <td className="border px-4 py-2">
                          Geotechnical construction services, including deep foundations, slurry walls, soil stabilization, and soil improvement; civil construction services, including grading, dam and levee construction, wetlands, stream and channel restoration; excavation services; project management; wildfire clean-up; disaster clean-up, environmental remediation, soil stabilization, soil containment, localization and consolidation of soil, site assessments.
                        </td>
                      </tr>
                      <tr>
                        <td className="border px-4 py-2">
                          <a href="https://tsdr.uspto.gov/#caseNumber=98121916&caseSearchType=US_APPLICATION&caseType=DEFAULT&searchType=statusSearch" target="_blank">98121916</a>
                        </td>
                        <td className="border px-4 py-2">Forgen, LLC</td>
                        <td className="border px-4 py-2">
                          Disaster response services, including removal of debris, soil, and vegetation; environmental remediation services, including soil treatment.
                        </td>
                      </tr>
                    </tbody>
                  </table>

                </div>
              </div>
            )}
            <button onClick={() => setShowMoreOpposition(!showMoreOpposition)} className="mt-2 text-blue-500 underline">
              {showMoreOpposition ? 'Show Less' : 'Show More'}
            </button>
          </article>

          <article className="bg-white p-6 rounded-lg shadow-md">
            <h3 className="text-xl font-semibold mb-2">ForGen Appoints COO</h3>
            <p>
              ForGen AI has appointed James D. Stevens as its new COO. Mr. Stevens has extensive experience managing large US and international intellectual property portfolios.
            </p>
            {showMoreCOO && (
              <p className="mt-2">
                With over 25 years in law firm management, he specializes in sectors such as autonomous vehicles, telecommunications, and medical devices. He also serves as an adjunct professor and has been named a "Top Lawyer" by dbusiness. For more on his background, visit his <a href="https://reising.com/profile/james-stevens-reising/" target="_blank" className="text-blue-500 underline">firm bio here</a>.
              </p>
            )}
            <button onClick={() => setShowMoreCOO(!showMoreCOO)} className="mt-2 text-blue-500 underline">
              {showMoreCOO ? 'Show Less' : 'Show More'}
            </button>
          </article>
        </div>
      </section>*/}

      <section className="relative py-12 bg-gray-100 z-10" id="learn-more">
        <h2 className="text-3xl font-bold text-center mb-4">About Us</h2>
        <p className="max-w-2xl mx-auto text-center px-4">
          Our company is dedicated to developing cutting-edge AI solutions that solve real-world problems. With a team of experts, we leverage the latest generative AI technology to deliver unmatched results for our clients and customers.
        </p>
      </section>

      <footer className="relative bg-gray-800 text-white py-4 z-10">
        <div className="flex justify-center space-x-4">
          <p>&copy; 2024 ForGen AI. All rights reserved.</p>
        </div>
      </footer>
    </div>
  )
}

export default LandingPage
