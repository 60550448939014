// components/Header.js
import React from 'react'

const Header = () => {
  return (
    <header className="text-center py-8">
      <img src="ForGenAI_Logo.png" alt="Logo" className="mx-auto max-h-[calc(50vh)] max-w-[800px]" />
      <h2 className="text-xl mt-2 italic ">For Generative AI Solutions</h2>
    </header>
  )
}

export default Header
