import React, { useState, useEffect } from 'react'
import { Amplify } from 'aws-amplify'
import '@aws-amplify/ui-react/styles.css'

import LandingPage from './LandingPage'
import FileInput from './FileInput'
import UrlInput from './UrlInput'
import Cluster from './Cluster'
import ClusterConfig from './ClusterConfig'
import Header from './Header'
import DescriptionText from './DescriptionText'
import DemoChat from './DemoChat'
import ChatWindow from './ChatWindow'
import PatentsChat from './PatentsChat'
import AssessmentsChat from './AssessmentsChat'

import awsExports from './aws-exports'
import { TextField, Button, Switch } from '@mui/material'
import FormControlLabel from '@mui/material/FormControlLabel'
import { performHybridSearch, performRag, fetchClusters } from './api'
Amplify.configure(awsExports)

function App() {
  const [query, setQuery] = useState('')
  const [answer, setAnswer] = useState('')
  const [searchResults, setSearchResults] = useState('')
  const [searchPerformed, setSearchPerformed] = useState(false)
  const [useVicuna, setUseVicuna] = useState(false)
  const [clusters, setClusters] = useState([])
  const [selectedCluster, setSelectedCluster] = useState(null)
  document.body.style.backgroundColor = 'gray'
  let timerId = null
  const debouncedHandler = (event) => {
    clearTimeout(timerId)
    timerId = setTimeout(() => {
      const newQuery = event.target.value
      setQuery(newQuery)
      performHybridSearch(newQuery, selectedCluster.cluster_id).then((results) => {
        setSearchResults(results)
      })
      setSearchPerformed(true)
    }, 666)
  }
  const handleClick = (cluster_id) => {
    const updatedClusters = clusters.map((cluster) => ({
      ...cluster,
      selected: cluster.cluster_id === cluster_id,
    }))
    setSelectedCluster(updatedClusters.find((cluster) => cluster.selected === true))
    setClusters(updatedClusters)
  }
  const fetchTheClusters = () => {
    fetchClusters().then((results) => {
      if (results !== 'error') {
        results = results.map((result, index) => ({
          ...result,
          selected: index === 0,
        }))
        setSelectedCluster(results.find((cluster) => cluster.selected === true))
        setClusters(results)
      }
    })
  }
  useEffect(() => {
    // fetchTheClusters()
  }, [])
  return (
    <div className="App" className="bg-white">
      <LandingPage />
    </div>
  )
}

export default App
